<template>
  <div>
    <p class="password-recover-description text-center my-8" v-html="description" />

    <div v-if="step === 'email-input'">
      <v-text-field
        v-model="email"
        autofocus
        :error-messages="emailError"
        label="E-mail"
        outlined
        required
        @input="notExistEmailError = false"
        @keydown.enter="handleClickButton"
      />
      <mf-button class="btn-submit-form" color="marca-0" :disabled="disableButton" label="Continuar" :loading="loading" @click="handleClickButton" />
    </div>

    <div v-else-if="step === 'pin-input' || step === 'dont-received-email'">
      <pin-input ref="pinInput" :error="pinError" @send-code="validateUserPin" />
      <div class="text-center mt-2">
        <span class="py-2 px-4 link" :class="disabledTimer ? 'disabled-timer' : ''" @click="validateUserByEmail">Redisparar e-mail {{ secondsText }}</span>
      </div>
      <div v-if="step !== 'dont-received-email'" class="text-center mt-4 mb-0">
        <span class="py-2 px-4 link" @click="step = 'dont-received-email'">Não recebi meu e-mail</span>
      </div>
      <div v-else-if="step === 'dont-received-email' && hasPhone" class="d-flex justify-center">
        <mf-button class="mt-5 unset-capitalize" color="marca-0-l10" label="Tentar por SMS" :loading="loading" outlined @click="trySms" />
      </div>
    </div>

    <div v-else-if="step === 'try-sms'">
      <v-menu v-model="notMyPhone" :close-on-content-click="false" content-class="not-my-phone-card text-center py-6 px-3" max-width="400" nudge-top="40" top>
        <template v-slot:activator="{ on }">
          <div class="text-center mb-4">
            <span class="py-2 px-4 link" v-on="on">Esse não é meu telefone</span>
          </div>
        </template>
        <div>
          <p class="mb-4">
            Entre em contato com o administrador da sua plataforma para conferir seus dados e solicitar a troca da sua senha de acesso pela plataforma
            Mercafacil.
          </p>
          <p>Em último caso entre em contato com: suporte@mercafacil.com</p>
          <mf-button
            class="mt-4 unset-capitalize"
            color="marca-0-l10"
            label="Fechar"
            :loading="loading"
            outlined
            @click="notMyPhone = false"
            @keydown.enter="handleClickButton"
          />
        </div>
      </v-menu>

      <div>
        <v-text-field
          v-model="phone"
          v-mask="['+55 (##) #####-####', '+55 (##) ####-####']"
          :error-messages="phoneError"
          :hide-details="false"
          label="Insira seu número de telefone"
          outlined
          @input="wrongPhone = false"
          @keydown.enter="handleClickButton"
        />
        <mf-button class="btn-submit-form" color="marca-0" :disabled="disableButton" label="Disparar código" :loading="loading" @click="handleClickButton" />
      </div>
    </div>

    <div v-else-if="step === 'sms-pin-input'">
      <pin-input ref="pinInput" :error="pinError" @send-code="validateUserPin" />
      <div class="text-center mt-4">
        <span class="py-2 px-4 link" :class="disabledTimer ? 'disabled-timer' : ''" @click="validateUserByPhone">Redisparar SMS {{ secondsText }}</span>
      </div>

      <div class="text-center mt-8 mb-0">
        <span v-if="!dontReceivedSms" class="py-2 px-4 link" @click="dontReceivedSms = true">Não recebi meu SMS</span>
      </div>
    </div>

    <div v-else-if="step === 'new-password-input'">
      <password-rules :validations="v$" />

      <v-text-field
        v-model="newPassword"
        :append-icon="visibilityNewPassword ? 'visibility_off' : 'visibility'"
        autocomplete="off"
        class="pt-1"
        :error-messages="newPasswordError"
        label="Nova senha"
        outlined
        tabindex="1"
        :type="visibilityNewPassword ? 'text' : 'password'"
        @click:append="() => (visibilityNewPassword = !visibilityNewPassword)"
        @keydown.enter="handleClickButton"
      />
      <v-text-field
        v-model="newPasswordConfirmation"
        :append-icon="visibilityNewPassword ? 'visibility_off' : 'visibility'"
        autocomplete="off"
        :error-messages="newPasswordConfirmationError"
        hint="As senhas devem ser iguais"
        label="Confirmar nova senha"
        outlined
        tabindex="2"
        :type="visibilityNewPassword ? 'text' : 'password'"
        @click:append="() => (visibilityNewPassword = !visibilityNewPassword)"
        @keydown.enter="handleClickButton"
      />
      <mf-button class="btn-submit-form" color="marca-0" :disabled="disableButton" label="Redefinir senha" :loading="loading" @click="handleClickButton" />
    </div>

    <back-to-login @go-back="goBack" />
  </div>
</template>

<script>
import { email, required, minLength, maxLength, sameAs } from '@vuelidate/validators'
import { containsUppercase, containsLowercase, containsNumber, notOnlySpace } from '@/helpers/validators'
import { QUERY_VALIDATE_USER_BY_EMAIL, QUERY_VALIDATE_PASSWORD_CONFIRMATION_TOKEN, MUTATION_CHANGE_PASSWORD_BY_EMAIL } from '../../graphql'
import { pinInput } from '../../mixins/pin-input'

export default {
  name: 'PasswordRecover',
  components: {
    PasswordRules: () => import('./PasswordRules.vue'),
  },
  mixins: [pinInput],
  props: {
    defaultEmail: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    /* Step email-input */
    email: '',
    passwordRecoveryToken: '',
    notExistEmailScreen: false,
    notExistEmailError: false,

    /* Step new-password-input */
    newPassword: '',
    newPasswordConfirmation: '',
    visibilityNewPassword: false,

    /* Step try-sms */
    hiddenPhone: '',
  }),
  computed: {
    description() {
      if (this.step === 'email-input') {
        if (this.notExistEmailScreen)
          return '<span>Parece que o e-mail informado não está associado a nenhuma conta Mercafacil. Confira a grafia do seu e-mail, se estiver correta entre em contato com o gestor da sua plataforma para confirmar que sua conta está ativa.</span> <p class="mt-4">Em último caso entre em contato com: suporte@mercafacil.com</p>'
        return 'Informe o e-mail associado a conta que você está tentando acessar para continuar'
      } else if (this.step === 'pin-input') {
        return `Disparamos um e-mail com um código de confirmação para <span class="highlited-text">${this.email}</span>, para criar uma nova senha de acesso, basta inserir o código recebido abaixo:`
      } else if (this.step === 'dont-received-email') {
        return `Tente procurar o e-mail de verificação na sua caixa de spam. Se ele não estiver lá, você pode entrar em contato com o administrador da sua plataforma para solicitar a troca do seu endereço de e-mail.
                <p class="mt-4">Em último caso entre em contato com: suporte@mercafacil.com</p>`
      } else if (this.step === 'try-sms') {
        return `Confirme seu número de telefone <span class="highlited-text">${this.hiddenPhone}</span>, completando-o na campo abaixo:`
      } else if (this.step === 'sms-pin-input') {
        if (this.dontReceivedSms)
          return `Você pode tentar redisparar o SMS ou entrar em contato com o administrador da sua plataforma para solicitar a troca da sua senha de acesso pela plataforma Mercafacil.
                <p class="mt-4">Em último caso entre em contato com: suporte@mercafacil.com</p>`
        return `Disparamos um SMS com um código de confirmação para <span class="highlited-text">${this.phone}</span>, para criar uma nova senha de acesso, basta inserir o código recebido abaixo:`
      }
      return ''
    },
    emailError() {
      if (this.notExistEmailError) return 'Não encontramos uma conta associada a esse e-mail.'
      if (this.v$.email?.$error) {
        if (this.v$.email.required.$invalid) return 'Insira um e-mail.'
        if (this.v$.email.email.$invalid) return 'Insira um e-mail válido.'
      }
      return ''
    },
    newPasswordError() {
      if (this.v$?.newPassword?.$error) {
        if (this.v$?.newPassword?.required.$invalid) {
          return 'Campo obrigatório.'
        }
        if (this.v$?.newPassword?.minLength.$invalid) {
          return 'Utilize pelo menos 10 caracteres.'
        }
        if (this.v$?.newPassword?.maxLength.$invalid) {
          return 'Utilize menos de 24 caracteres.'
        }
        if (this.v$?.newPassword?.containsUppercase.$invalid) {
          return 'Utilize pelo menos uma letra maiúscula.'
        }
        if (this.v$?.newPassword?.containsLowercase.$invalid) {
          return 'Utilize pelo menos uma letra minúscula.'
        }
        if (this.v$?.newPassword?.containsNumber.$invalid) {
          return 'Utilize pelo menos um número.'
        }
      }
      return ''
    },
    newPasswordConfirmationError() {
      if (this.v$?.newPasswordConfirmation?.$error) {
        if (this.v$?.newPasswordConfirmation?.samePassword.$invalid) {
          return 'Repita a senha anterior.'
        }
      }
      return ''
    },
  },
  created() {
    // verify if typed email at login is valid to set to input
    const isEmailValid = email.$validator(this.defaultEmail)
    if (isEmailValid) this.email = this.defaultEmail
  },
  methods: {
    handleClickButton() {
      if (this.loading || this.disableButton) return
      this.v$.$reset()
      this.v$.$touch()
      if (this.v$?.$error) return
      if (this.step === 'email-input') {
        this.validateUserByEmail()
      }

      if (this.step === 'new-password-input') {
        this.changeUserPassword()
      }

      if (this.step === 'try-sms') {
        this.validateUserByPhone()
      }
    },
    trySms() {
      this.clearInterval()
      this.step = 'try-sms'
    },
    async validateUserByEmail() {
      if (this.disabledTimer) return
      this.pinError = ''
      if (this.$refs && this.$refs.pinInput) this.$refs.pinInput.code = new Array(6)
      this.loading = true
      try {
        const {
          data: {
            validateUserByEmail: { has_phone, hidden_phone, passwordRecoveryToken },
          },
        } = await this.$apollo.mutate({
          mutation: QUERY_VALIDATE_USER_BY_EMAIL,
          fetchPolicy: 'no-cache',
          variables: { email: this.email },
          context: {
            headers: {
              fingerprint: this.fingerprint,
            },
          },
        })
        this.hasPhone = has_phone
        this.hiddenPhone = hidden_phone
        this.passwordRecoveryToken = passwordRecoveryToken
        this.step = 'pin-input'
        this.startTimer()
      } catch (error) {
        console.log(error)
        if (error.graphQLErrors?.[0]?.message === 'Email not match') {
          this.$snackbar({ message: 'E-mail não associado a uma conta Mercafacil', snackbarColor: 'error' })
          this.notExistEmailScreen = true
          this.notExistEmailError = true
        }
      } finally {
        this.loading = false
      }
    },
    async validateUserPin(token) {
      this.pinError = ''
      if (token.length !== 6) return
      this.loading = true
      try {
        const {
          data: { recoveryPasswordConfirmationToken },
        } = await this.$apollo.mutate({
          mutation: QUERY_VALIDATE_PASSWORD_CONFIRMATION_TOKEN,
          fetchPolicy: 'no-cache',
          variables: { confirmationToken: this.passwordRecoveryToken, verificationCode: token },
          context: {
            headers: {
              fingerprint: this.fingerprint,
            },
          },
        })
        if (recoveryPasswordConfirmationToken) this.step = 'new-password-input'
      } catch (error) {
        console.log(error)
        if (error.graphQLErrors?.[0]?.message === 'Recovery password code wrong') {
          this.$snackbar({ message: 'Código de verificação inválido', snackbarColor: 'error' })
          this.pinError = 'Código incorreto'
        }
      } finally {
        this.loading = false
      }
    },
    async changeUserPassword() {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_CHANGE_PASSWORD_BY_EMAIL,
          fetchPolicy: 'no-cache',
          variables: { email: this.email, newPassword: this.newPassword },
          context: {
            headers: {
              fingerprint: this.fingerprint,
            },
          },
        })
        this.$snackbar({ message: 'Senha alterada com sucesso!', snackbarColor: 'success' })
        this.goBack()
      } catch (error) {
        console.log(error)
        this.$snackbar({ message: 'Houve um erro ao alterar a sua senha. Tente novamente mais tarde.', snackbarColor: 'error' })
      } finally {
        this.loading = false
      }
    },
  },
  validations() {
    if (this.step === 'email-input') {
      return {
        email: {
          required,
          notOnlySpace,
          email,
        },
      }
    } else if (this.step === 'new-password-input') {
      return {
        newPassword: {
          required,
          notOnlySpace,
          minLength: minLength(10),
          maxLength: maxLength(24),
          containsUppercase,
          containsLowercase,
          containsNumber,
        },
        newPasswordConfirmation: { samePassword: sameAs(this.newPassword) },
      }
    } else if (this.step === 'try-sms') {
      return {
        phone: { required, notOnlySpace, minLength: minLength(18), maxLength: maxLength(19) },
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.password-recover-description {
  color: $cinza-0;
  font-size: 16px;
  line-height: 120%;
}

::v-deep .highlited-text {
  color: $marca-1;
  font-weight: bold;
}

.unset-capitalize {
  text-transform: unset !important;
  letter-spacing: 0.02px !important;
}

.not-my-phone-card {
  border-radius: 8px;
  border: 1px solid $cinza-l60;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.18);
  background-color: $branco;
  p {
    color: $cinza-d10;
    font-size: 16px;
    line-height: 120%;
  }
}

.disabled-timer {
  color: $cinza-l10 !important;
  cursor: not-allowed !important;
}
</style>
