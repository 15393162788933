import useVuelidate from '@vuelidate/core'

import { QUERY_VALIDATE_USER_BY_PHONE } from '../graphql'
import { getFingerprint } from '@/services/fingerprint.js'

export const pinInput = {
  components: {
    PinInput: () => import('@/components/PinInput.vue'),
    BackToLogin: () => import('@/components/BackToLogin.vue'),
  },
  setup() {
    return { v$: useVuelidate('', '', { $scope: false }) }
  },
  data: () => ({
    loading: false,
    tryWith: 'email',
    fingerprint: '',

    /* Stepper */
    step: 'email-input',

    /* Step pin-input */
    pinError: '',

    /* Step try-sms */
    hasPhone: false,
    notMyPhone: false,
    wrongPhone: false,
    phone: '',

    /* Step sms-pin-input */
    timer: 0,
    interval: null,
    dontReceivedSms: false,
  }),
  computed: {
    disableButton() {
      if (this.step === 'email-input') return !this.email
      if (this.step === 'try-sms') return !this.phone
      if (this.step === 'new-password-input') {
        return !this.newPassword || !this.newPasswordConfirmation || this.newPassword !== this.newPasswordConfirmation
      }

      return false
    },
    phoneError() {
      if (this.wrongPhone) return 'Seu telefone não parece correto.'
      if (this.v$.phone?.$error) return 'Insira um número de telefone válido.'
      return ''
    },
    secondsText() {
      if (this.timer === 0) return ''
      return this.timer > 1 ? `em ${this.timer} segundos` : '1 segundo'
    },
    disabledTimer() {
      return this.timer > 0
    },
  },
  beforeUnmount() {
    this.clearInterval()
  },
  async created() {
    const fingerprint = await getFingerprint()
    this.$setFingerPrint(fingerprint)
    this.fingerprint = fingerprint.visitorId
  },
  methods: {
    goBack() {
      this.$emit('go-back')
    },
    startTimer() {
      this.clearInterval()
      this.timer = 60
      this.interval = setInterval(() => {
        this.timer--
        if (this.timer === 0) this.clearInterval()
      }, 1000)
    },
    clearInterval() {
      clearInterval(this.interval)
      this.interval = null
      this.timer = 0
    },
    async validateUserByPhone() {
      if (this.disabledTimer) return
      this.pinError = ''
      if (this.$refs && this.$refs.pinInput) this.$refs.pinInput.code = new Array(6)
      this.loading = true
      try {
        const {
          data: {
            validateUserByPhone: { passwordRecoveryToken },
          },
        } = await this.$apollo.mutate({
          mutation: QUERY_VALIDATE_USER_BY_PHONE,
          fetchPolicy: 'no-cache',
          variables: { phone: this.phone },
          context: {
            headers: {
              fingerprint: this.fingerprint,
            },
          },
        })
        this.passwordRecoveryToken = passwordRecoveryToken
        this.step = 'sms-pin-input'
        this.startTimer()
      } catch (error) {
        console.log(error)
        if (error.graphQLErrors?.[0]?.message === 'Phone not match') {
          this.$snackbar({ message: 'Telefone não associado a essa conta', snackbarColor: 'error' })
          this.wrongPhone = true
        }
      } finally {
        this.loading = false
      }
    },
  },
}
